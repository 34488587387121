var i18n={
    en:{
        overtime_1:`Error code: `,
        overtime_2:`, plesase retry it`,
        isnetwork:`Internet error. Please check and retry`,
        email_placeholder:`Email`,
        email_empty:`Please input email`,
        email_not_valid:`Email is not valid`,
        email_not_valid_1:`Please input your email`,
        email_not_valid_2:`Email is not valid, please use a different address.`,
        email_not_valid_3:`No email input`,
        password_placeholder:`Password`,
        password_empty:`Please input password`,
        password_not_valid:`Incorrect account or password`,
        password_not_valid_1:`Password above 8 characters is required`,
        password_not_valid_2:`Please create a password`,
        password_placeholder_1:`Create your password`,
        password_placeholder_2:`Confirm your Password`,
        password_placeholder_3:`Create new password`,
        password_placeholder_4:`Confirm new password`,
        password_placeholder_5:`Input old password`,
        copy_password_empty:`Please confirm password`,
        copy_password_not_valid:`Please confirm your password`,
        copy_passwords_inconsistent:`Your password confirmation does not match`,
        code_empty:`Please input verification code`,
        code_not_valid:`Invalid verification code`,
        code_placeholder:`Verification Code`,
        not_received_code:`If your mailbox has not received the verification code for a long time, please get the verification code again.`,
        get_first_code:`Please get the verification code first.`,
        connect_your_email_placeholder:`Connect your email`,
        last_name_placeholder:`Please enter your last name`,
        first_name_placeholder:`Please enter your first name`,
        address_placeholder:`Please enter your address`,
        no_code_text:`We've sent a verification code. Please enter your code. <span class='tips'>Didn't receive a code?`,
        no_code_text_1:`1. Please make sure the email address is valid and it can receive emails.`,
        no_code_text_2:`2. For the email is sent by system automatically, it may be flagged as the spam or junk email. Please check whether the email is in the Trash folder.`,
        no_code_text_3:`3. Cannot solve your problem? `,
        no_code_text_3_span:`Then click here to contact us.`,
        order_no:`You have not purchased any product, <a href="https://www.anycoord.com/store/">shop now</a>! If you have any question, please <a href="https://www.anycoord.com/contact.html">contact us</a>.`,
        error_24901:`Current account has no email linked, and cannot find the orders. Please connect an email.`,
        user_guide:`User Guide>>`,
        download:`Download`,
        order_number:`Order #`,
        Refund:`Refund`,
        Disabled:`Disabled`,
        Normal:`Normal`,
        Modify:`Modify`,
        Modify_1:`Modify>>`,
        Connect:`Connect`,
        unlink_success:`Unlink successfully`,
        connect_success:`Connect successfully`,
        feedback_title:`Thanks for your feedback. Please leave your problem and we will respond to you within 24 hours.`,
        feedback_thank_you:`Thank You!<br />Your Feedback has been submitted successfully.`,
        feedback_email:`Enter your email here!`,
        feedback_content:`Leave any problem or suggestion you encountered here.`,
        feedback_submit:`Submit`,
        form_contents:`You have not entered any description. Please enter it and submit again.`,
        old_password:`Please input old password`,
        new_password:`Please create a new password`,
        old_new_password:`The new password cannot be the same as the old one`,
        incorrect_password:`Incorrect password`,
        delete_no:`Delete Now`,
        Caps:`Caps lock is on`,
        Get:`Get`,
        Send:`Send`,
        Done:`Done`,
        error_20001:`Error: 20001. Please log in again.`,
        error_20002:`Error: 20002. Please log in again.`,
        error_20003:`Error: 20003. Please log in again.`,
        error_20004:`Request failed (error code: 20004). Please try it again.`,
        error_20005:`Login session has expired (Error: 20005). Please log in again.`,
        error_20006:`Request failed (error code: 20006). Please try it again.`,
        error_20007:`Login session has expired (Error: 20007). Please log in again.`,
        error_20008:`Login session has expired (Error: 20008). Please log in again.`,
        error_20009:`Login session has expired (Error: 20009). Please log in again.`,
        error_20101:`Please input your email (error code: 20101)`,
        error_20102:`Email is not valid (error code: 20102)`,
        error_20103:`Request failed (error code: 20103). Please try it again`,
        error_20104:`Email is already in use, please <a href="https://account.anycoord.com/login">log in</a> or register with a new one`,
        error_20105:`Request failed (error code: 20105). Please try it again`,
        error_20106:`Failed to send email, please retry it`,
        error_20201:`Please input your email (error code: 20201)`,
        error_20202:`Please input your password (error code: 20202)`,
        error_20203:`Please input verification code (error code: 20203)`,
        error_20204:`Email is not valid (error code: 20204)`,
        error_20205:`Password above 8 characters is required (error code: 20205)`,
        error_20206:`Request failed (error code: 20206). Please try it again`,
        error_20207:`Invalid verification code`,
        error_20208:`Request failed (error code: 20208). Please try it again`,
        error_20209:`Request failed (error code: 20209). Please try it again`,
        error_20301:`Please input your email (error code: 20301)`,
        error_20302:`Error: 20302. Please contact us`,
        error_20303:`Email is not valid (error code: 20303)`,
        error_20304:`Request failed (error code: 20304). Please try it again`,
        error_20305:`Account does not exist. Please re-enter or <a href="https://account.anycoord.com/register">Create it</a> first.`,
        error_20306:`No password yet. Use <a href="https://account.anycoord.com/passwordless-login">Passwordless login</a> or <a href="create-password">set a password</a> and log in.`,
        error_20308:`Request failed (error code: 20308). Please try it again`,
        error_20401:`Failed to log out (error code: 20401). Please try it again`,
        error_20501:`Please input your email (error code: 20501)`,
        error_20502:`Email is not valid (error code: 20502)`,
        error_20503:`Request failed (error code: 20503). Please try it again`,
        error_20504:`Failed to send email. Please try it again.`,
        error_20601:`Please input your email (error code: 20601)`,
        error_20602:`Please input verification code (error code: 20602)`,
        error_20603:`Email is not valid (error code: 20603)`,
        error_20604:`Request failed (error code: 20604). Please try it again`,
        error_20606:`Request failed (error code: 20606). Please try it again`,
        error_20607:`Request failed (error code: 20607). Please try it again`,
        error_20608:`Request failed (error code: 20608). Please try it again`,
        error_20701:`Please input your email (error code: 20701)`,
        error_20702:`Email is not valid (error code: 20702)`,
        error_20703:`Request failed (error code: 20703). Please try it again`,
        error_20704:`Account does not exist. Please re-enter or <a href="https://account.anycoord.com/register">Create it</a> first.`,
        error_20705:`Request failed (error code: 20705). Please try it again`,
        error_20706:`Failed to send email. Please try it again`,
        error_20801:`Please input your email (error code: 20801)`,
        error_20802:`Error: 20802. Please contact us`,
        error_20803:`Please input verification code (error code: 20803)`,
        error_20804:`Email is not valid (error code: 20804)`,
        error_20805:`Password above 8 characters is required (error code: 20805)`,
        error_20806:`Request failed (error code: 20806). Please try it again`,
        error_20808:`Request failed (error code: 20808). Please try it again`,
        error_20901:`Request failed (error code: 20901). Please try it again`,
        error_20902:`Request failed (error code: 20902). Please try it again`,
        error_21000:`Changes are saved`,
        error_21001:`No information is submitted (error code: 21001)`,
        error_21002:`Request failed (error code: 21002). Please try it again`,
        error_21101:`Please input your email (error code: 21101)`,
        error_21102:`Email is not valid (error code: 21102)`,
        error_21103:`Request failed (error code: 21103), plesase retry it again`,
        error_21104:`Email is already connected, please use a new one`,
        error_21105:`Request failed (error code: 21105), plesase retry it again`,
        error_21106:`Failed to send email. Please try it again`,
        error_21201:`Please input your email (error code: 21201)`,
        error_21202:`Please input verification code (error code: 21202)`,
        error_21203:`Email is not valid (error code: 21203)`,
        error_21204:`Error: 21204. Please contact us`,
        error_21205:`Error: 21205. Please contact us`,
        error_21206:`Password above 8 characters is required (error code: 21206)`,
        error_21207:`Request failed (error code: 21207). Please try it again`,
        error_21209:`Request failed (error code: 21209). Please try it again`,
        error_21301:`Please input old password (error code: 21301)`,
        error_21302:`Please create a new password (error code: 21302)`,
        error_21303:`The new password cannot be the same as the old one. (Error: 21303)`,
        error_21304:`Password above 8 characters is required (error code: 21304)`,
        error_21306:`Request failed (error code: 21306). Please try it again`,
        error_21402:`Request failed (error code: 21402). Please try it again`,
        error_21403:`Failed to send verification code. Please resend it`,
        error_21500:`The account has been deleted`,
        error_21501:`Please input verification code (error code: 21501)`,
        error_21502:`Login session has expired (Error: 21502). Please log in again.`,
        error_21503:`Request failed (error code: 21503). Please try it again`,
        error_21505:`Request failed (error code: 21505), plesase retry it`,
        error_21601:`Error: 20601. Please contact us`,
        error_21602:`Invalid Verification (Error: 20602). Please try it again.`,
        error_21603:`Error: 20603. Please try it again`,
        error_21604:`Request failed (error code: 21604). Please try it again`,
        error_21606:`Request failed (error code: 21606). Please try it again`,
        error_21611:`Request failed (error code: 21611). Please try it again`,
        error_21801:`Error: 21801. Please contact us`,
        error_21802:`Request failed (Error: 21802). Please try it again`,
        error_21803:`Error: 21803. Please try it again`,
        error_21804:`Request failed (error code: 21804). Please try it again`,
        error_21806:`Error: 21806. Please try it again`,
        error_21807:`Error: 21807. Please contact us`,
        error_21808:`Error: 21808. Please contact us`,
        error_21809:`Error: 21809. Please contact us`,
        error_21810:`Error: 21810. Please contact us`,
        error_21811:`Error: 21811. Please contact us`,
        error_21812:`Error: 21812. Please contact us`,
        error_21813:`Request failed (error code: 21813). Please try it again`,
        error_21814:`Error: 21814. Please contact us`,
        error_21815:`Request failed (error code: 21815). Please try it again`,
        error_21816:`Error: 21816. Please contact us`,
        error_21817:`Error: 21817. Please contact us`,
        error_21818:`Error: 21818. Please contact us`,
        error_21819:`Request failed (error code: 21819). Please try it again`,
        error_21820:`Error: 21820. Please contact us`,
        error_21821:`Error: 21821. Please contact us`,
        error_21822:`Error: 21822. Please contact us`,
        error_21823:`Request failed (error code: 21823). Please try it again`,
        error_21824:`Request failed (error code: 21824). Please try it again`,
        error_21825:`Request failed (error code: 21825). Please try it again`,
        error_21826:`Request failed (error code: 21826). Please try it again`,
        error_21828:`Error: 21828. Please contact us`,
        error_21901:`Error: 21901. Please contact us`,
        error_21902:`Request failed (error code: 21902). Please try it again`,
        error_21903:`The account status has changed (error code: 21903), please refresh the page and try again`,
        error_21904:`Error: 21904. Please try it again`,
        error_21905:`Error: 21905. Please try it again`,
        error_21906:`Request failed (error code: 21906). Please try it again`,
        error_21907:`Google account has been linked to other account`,
        error_21908:`Request failed (error code: 21908). Please try it again`,
        error_22001:`Request failed (error code: 22001). Please try it again`,
        error_22002:`Unlink failed with no additional login`,
        error_22003:`Request failed (error code: 22003). Please try it again`,
        error_22101:`Error: 22101. Please contact us`,
        error_22102:`The account status has changed (error code: 22102), please refresh the page and try again`,
        error_22103:`Request failed (error code: 22103). Please try it again`,
        error_22104:`The account status has changed (error code: 22104), please refresh the page and try again`,
        error_22105:`Error: 22105. Please try it again`,
        error_22106:`Error: 22106. Please try it again`,
        error_22107:`Error: 22107. Please contact us`,
        error_22108:`Request failed (error code: 22108). Please try it again`,
        error_22201:`Error: 22201. Please contact us`,
        error_22202:`Invalid Verification (Error: 22202). Please try it again.`,
        error_22203:`Error: 22203. Please try it again"`,
        error_22204:`Request failed (error code: 22204). Please try it again`,
        error_22206:`Request failed (error code: 22206). Please try it again`,
        error_22401:`Error: 22401. Please contact us`,
        error_22402:`Invalid Verification (Error: 22402). Please try it again.`,
        error_22403:`Error: 22403. Please try it again`,
        error_22404:`Request failed (error code: 22404). Please try it again`,
        error_22405:`Facebook account has been linked to other email`,
        error_22406:`Error: 22406. Please try it again`,
        error_22407:`Error: 22407. Please contact us`,
        error_22408:`Error: 22408. Please contact us`,
        error_22409:`Error: 22409. Please contact us`,
        error_22410:`Error: 224010. Please contact us`,
        error_22411:`Error: 224011. Please contact us`,
        error_22412:`Error: 224012. Please contact us`,
        error_22413:`Error: 22413. Please contact us`,
        error_22414:`Request failed (error code: 22414). Please try it again`,
        error_22415:`Error: 22415. Please contact us`,
        error_22416:`Error: 22416. Please contact us`,
        error_22417:`Error: 22417. Please contact us`,
        error_22418:`Request failed (error code: 22418). Please try it again`,
        error_22419:`Error: 22419. Please contact us`,
        error_22420:`Error: 22420. Please contact us`,
        error_22421:`Error: 22421. Please contact us`,
        error_22422:`Request failed (error code: 22422). Please try it again`,
        error_22423:`Request failed (error code: 22423). Please try it again`,
        error_22424:`Request failed (error code: 22424). Please try it again`,
        error_22425:`Request failed (error code: 22425). Please try it again`,
        error_20098:`Error code: 20098. If you are browsing in Private mode, please switch to Normal mode and try again.`,
        error_22298:`Request for Google failed (Error: 22298). Please try it again.`,
        error_22498:`Request for Facebook failed (Error: 22498). Please try it again.`,
        error_24902:`Request failed (error code: 24902). Please try it again`,
        error_24903:`Request failed (error code: 24903). Please try it again`,
        error_24904:`Request failed (error code: 24904). Please try it again`,
        error_24905:`Request failed (error code: 24905). Please try it again`,
        login_title:`Log In`,
        password_placeholder:`Password`,
        log_in:`Log in`,
        no_account:`Don't have an account?`,
        create_it:`Sign up now`,
        or_log_in_with:`Or log in with`,
        passwordless_login:`Passwordless login`,
        log_in_done:`Log In Done`,
        three_rd_account_connect_info:`Congratulations! You have logged in successfully. Now you can connect one email account that is used to log in in the future.`,
        connect_now:`Connect now`,
        see_my_account:`See my account`,
        three_rd_login_merge_account:`The third-party account email address has been signed up, do you want to connect and log in with this email address directly?`,
        connect_log_in:`Connect & Log In`,
        create_an_account:`Create an account`,
        connect_account:`Connect Account`,
        connect_account_info:`After connecting, you can log in with this account.`,
        back_to_log_in:`Back to Login`,
        create_password:`Create Password`,
        create_now:`Create Now`,
        password_login_subtitle:`Passwordless login with email`,
        account_login:`Account Login`,
        rights:`By creating account, I agree that I have read and accepted the <a href="https://www.anycoord.com/company/terms-and-conditions/">Terms of Use</a> and <a href="https://www.anycoord.com/company/privacy-policy/">Privacy Policy</a>.`,
        passwordless_login_done:`Passwordless login done`,
        passwordless_login_info:`Congratulations, you have completed passwordless login successfully. You can create a password for this account and log in with the account and password in the future. <a href="/create-password" style="display: initial;">Create Now</a>`,
        sign_up:`Sign Up`,
        sign_up1:`Sign up`,
        register_info:`Create your account`,
        reset:`Reset`,
        forgot_password:`Forgot Password`,
        reset_password:`Reset Password`,
        reset_password_subtitle:`Use your account email to reset password`,
        plan_products:`Plans & Products`,
        nick_name:`Username:`,
        email:`Email:`,
        my_products:`My Products`,
        my_orders:`My Orders`,
        unlink:`Unlink`,
        link:`Link`,
        connected_accounts:`Connected accounts`,
        last_name:`Last name:`,
        first_name:`First name:`,
        Gender:`Gender:`,
        Birth:`Birthday:`,
        Month:`Month`,
        Year:`Year`,
        Country_Region:`Country/Region:`,
        Address:`Address:`,
        Save:`Save`,
        Date:`Date`,
        Male:`Male`,
        Female:`Female`,
        Unspecified:`Unspecified`,
        Security:`Security`,
        change_password:`Change Password`,
        change_now:`Change Now`,
        connect_email:`Connect email`,
        delete_account:`Delete Account`,
        delete_account_info_1:`1. Deleting your AnyCoord account is a permanent action and cannot be undone. All your data, including photos and preferences, will be lost. Are you sure to delete your AnyCoord account?`,
        delete_account_info_2:`2. If you decide to delete the AnyCoord account, please click on the Send button and enter the verification code from the email we sent you.`,
        Delete:`Delete`,
        Logout:`Logout`,
        my_profile:`My Profile`,
        guides_faqs:`Guides & FAQs`,
        More:`More`,
        guides:`Guides`,
        register:`register`,
        hot_faq:`Hot FAQ`,
        Contents:`Contents:`,
        contact_us:`Contact us>>`,
        plan:`Plan`,
        unregistered:`Unregistered`,
        buy_more:`Purchase More`,
        buy_again:`Buy Again`,
        buy_now:`Buy Now`,
        free_no_limit:`Free & No limit`,
        expired:`Expired`,
        lifetime:`Lifetime`,
        remain:`Remain`,
        day_s:`Day(s)`,
        error_24801:`Request failed (error code: 24801). Please try it again`,
        no_app_found:`No app found！<a href="/">Refresh</a> or <a href="https://www.anycoord.com/">Go to the official website</a>`,
        get_more:`Get More >>`,
        edit_photo:`Edit Photo`,
        select_photo:`Select photo`,
        change_photo:`Change photo`,
        cancel:`Cancel`,
        hide_password:`Hide password`,
        show_password:`Show password`,
        zoom_in:`Zoom in`,
        zoom_out:`Zoom out`,
        rotate:`Rotate`,
        horizontal_flip:`Horizontal flip`,
        vertical_flip:`Vertical flip`,
        country:`Country`,
        country_1:`Select your country/region`,
        country_2:`Åaland Island`,
        country_3:`Afghanistan`,
        country_4:`Albania`,
        country_5:`Algeria`,
        country_6:`American Samoa`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarctica`,
        country_11:`Antigua & Barbuda`,
        country_12:`Argentina`,
        country_13:`Armenia`,
        country_14:`Aruba`,
        country_15:`Australia`,
        country_16:`Austria`,
        country_17:`Azerbaijan`,
        country_18:`Bahrain`,
        country_19:`Bangladesh`,
        country_20:`Barbados`,
        country_21:`Belarus`,
        country_22:`Belgium`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Bhutan`,
        country_27:`Bolivia`,
        country_28:`Bosnia & Herzegovina`,
        country_29:`Botswana`,
        country_30:`Bouvet Island`,
        country_31:`Brazil`,
        country_32:`British Indian Ocean Territory`,
        country_33:`British Virgin Islands`,
        country_34:`Brunei`,
        country_35:`Bulgaria`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Cambodia`,
        country_39:`Cameroon`,
        country_40:`Canada`,
        country_41:`Cape Verde`,
        country_42:`Caribbean Netherlands`,
        country_43:`Cayman Islands`,
        country_44:`Central African Republic`,
        country_45:`Chad`,
        country_46:`Chile`,
        country_47:`China`,
        country_48:`Christmas Island`,
        country_49:`Cocos (Keeling) Islands`,
        country_50:`Colombia`,
        country_51:`Cook Islands`,
        country_52:`Costa Rica`,
        country_53:`Côte d'Ivoire`,
        country_54:`Croatia`,
        country_55:`Cuba`,
        country_56:`Cyprus`,
        country_57:`Czech Republic`,
        country_58:`Democratic Republic of the Congo`,
        country_59:`Denmark`,
        country_60:`Djibouti`,
        country_61:`Dominica`,
        country_62:`Dominican Republic`,
        country_63:`Ecuador`,
        country_64:`Egypt`,
        country_65:`El Salvador`,
        country_66:`Equatorial Guinea`,
        country_67:`Eritrea`,
        country_68:`Estonia`,
        country_69:`Ethiopia`,
        country_70:`Falkland Islands`,
        country_71:`Faroe Islands`,
        country_72:`Federated States of Micronesia`,
        country_73:`Fiji`,
        country_74:`Finland`,
        country_75:`France`,
        country_76:`French Guiana`,
        country_77:`French polynesia`,
        country_78:`French Southern Territories`,
        country_79:`Gabon`,
        country_80:`Gambia`,
        country_81:`Georgia`,
        country_82:`Germany`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Great Britain (United Kingdom; England)`,
        country_86:`Greece`,
        country_87:`Greenland`,
        country_88:`Grenada`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guinea`,
        country_94:`Guinea-Bissau`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Heard Island and McDonald Islands`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Hungary`,
        country_101:`Iceland`,
        country_102:`India`,
        country_103:`Indonesia`,
        country_104:`Iran`,
        country_105:`Iraq`,
        country_106:`Ireland`,
        country_107:`Isle of Man`,
        country_108:`Israel`,
        country_109:`Italy`,
        country_110:`Jamaica`,
        country_111:`Japan`,
        country_112:`Jersey`,
        country_113:`Jordan`,
        country_114:`Kazakhstan`,
        country_115:`Kenya`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Kyrgyzstan`,
        country_119:`Laos`,
        country_120:`Latvia`,
        country_121:`Lebanon`,
        country_122:`Lesotho`,
        country_123:`Liberia`,
        country_124:`Libya`,
        country_125:`Liechtenstein`,
        country_126:`Lithuania`,
        country_127:`Luxembourg`,
        country_128:`Macao`,
        country_129:`Madagascar`,
        country_130:`Malawi`,
        country_131:`Malaysia`,
        country_132:`Maldives`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Marshall islands`,
        country_136:`Martinique`,
        country_137:`Mauritania`,
        country_138:`Mauritius`,
        country_139:`Mayotte`,
        country_140:`Mexico`,
        country_141:`Moldova`,
        country_142:`Monaco`,
        country_143:`Mongolia`,
        country_144:`Montenegro`,
        country_145:`Montserrat`,
        country_146:`Morocco`,
        country_147:`Mozambique`,
        country_148:`Myanmar (Burma)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Netherlands`,
        country_153:`New Caledonia`,
        country_154:`New Zealand`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Norfolk Island`,
        country_160:`North Korea`,
        country_161:`Northern Mariana Islands`,
        country_162:`Norway`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`Palestinian territories`,
        country_167:`Panama`,
        country_168:`Papua New Guinea`,
        country_169:`Paraguay`,
        country_170:`Peru`,
        country_171:`Pitcairn Islands`,
        country_172:`Poland`,
        country_173:`Portugal`,
        country_174:`Puerto Rico`,
        country_175:`Qatar`,
        country_176:`Republic of Macedonia (FYROM)`,
        country_177:`Republic of the Congo`,
        country_178:`Réunion`,
        country_179:`Romania`,
        country_180:`Russian Federation`,
        country_181:`Rwanda`,
        country_182:`Saint Barthélemy`,
        country_183:`Saint Martin (France)`,
        country_184:`Saint-Pierre and Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Sao Tome & Principe`,
        country_188:`Saudi Arabia`,
        country_189:`Senegal`,
        country_190:`Serbia`,
        country_191:`Seychelles`,
        country_192:`Sierra Leone`,
        country_193:`Singapore`,
        country_194:`Slovakia`,
        country_195:`Slovenia`,
        country_196:`Solomon Islands`,
        country_197:`Somalia`,
        country_198:`South Africa`,
        country_199:`South Georgia and the South Sandwich Isla`,
        country_200:`South Korea`,
        country_201:`South Sudan`,
        country_202:`Spain`,
        country_203:`Sri Lanka`,
        country_204:`St. Helena & Dependencies`,
        country_205:`St. Kitts & Nevis`,
        country_206:`St. Lucia`,
        country_207:`St. Vincent & the Grenadines`,
        country_208:`Sudan`,
        country_209:`Suriname`,
        country_210:`Swaziland`,
        country_211:`Sweden`,
        country_212:`Switzerland`,
        country_213:`Syria`,
        country_214:`Taiwan`,
        country_215:`Tajikistan`,
        country_216:`Tanzania`,
        country_217:`Template:Country data SJM Svalbard`,
        country_218:`Thailand`,
        country_219:`The Bahamas`,
        country_220:`The Comoros`,
        country_221:`The Philippines`,
        country_222:`Timor-Leste (East Timor)`,
        country_223:`Togo`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad & Tobago`,
        country_227:`Tunisia`,
        country_228:`Turkey`,
        country_229:`Turkmenistan`,
        country_230:`Turks & Caicos Islands`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ukraine`,
        country_234:`United Arab Emirates`,
        country_235:`United States Minor Outlying Islands`,
        country_236:`United States of America (USA)`,
        country_237:`United States Virgin Islands`,
        country_238:`Uruguay`,
        country_239:`Uzbekistan`,
        country_240:`Vanuatu`,
        country_241:`Vatican City (The Holy See)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis and Futuna`,
        country_245:`Western Sahara`,
        country_246:`Yemen`,
        country_247:`Zambia`,
        country_248:`Zimbabwe`,
        google_login:`Sign in with Google`,
        State:`State`,
        Activation_code:`Activation code`,
        Question:`List all the apps you have logged in`,
        Copy_complete:`Copy complete`,

        change_password_success:`Password changed successfully`,
        successful_login_title:`Login Success`,
        product_page:`Product Page>>`,
        successful_login_info:`Login completed. Please close the current page and go back to the original tab to continue with the following process. The current page will be closed automatically in 5 seconds. If auto-closing or closing by clicking the "Done" button fails, please close this tab directly.`,
        successful_login_info_firefox:`Login completed. Please close the current page and go back to the original tab to continue with the following process.`,
        home:`Home`,
        account_center:`Account Center`,
        history_files:`History Files`,
        remove_watermark:`Remove Watermark`,
        no_history:`No History`,
        history_all:`Select all`,
        history_open:`Open`,
        history_down:`Download`,
        history_delete:`Delete`,
        history_clear:`Clear invalid`,
        images:`image(s)`,
        use_this_function:`Use This Product>>`,
        hd_downloading:`HD original images downloading benefits:`,
        lifetimeRemaining:`Lifetime-Remaining`,
        Remaining:`Remaining`,
        email_verification:`Email Verification`,
        email_verification_info:`We have sent a verification email to your email address <span class="email"></span>. Please finish the verification to use our services without restriction.`,
        wrong_email:`Wrong email address?`,
        click_here_to_modify:`Click here to modify`,
        click_here:`Click here`,
        email_verification_info_success:`You have successfully verified your email address.`,
        email_verification_info_error:`Verification failed because of the expired link.`,
        registration_succeeded:`Registration Succeeded`,
        registration_succeeded_info_1:`We have sent a verification email to your email address <span class="email"></span>. Please finish the verification to use our services without restriction.`,
        registration_succeeded_info_2:`Click "Done" to return to the Home page and use this product.`,
        registration_succeeded_info_3:`Click "Done" to close the current page and return to the Home page for the following operations. If closing fails, please manually close the tab.`,
        verify_email:`Verify Email`,
        registered_email_not_verified:`The registered email has not been verified, please verify it at once.`,
        email_verification_time_1:`Didn't receive any email?`,
        email_verification_time_2:`After`,
        email_verification_time_3:`Click here to resend.`,
        verification_failed:`verification_failed`,
        error_26301:`Error code: 26301, please contact us`,
        error_26302:`Error code: 26302, please contact us`,
        error_26303:`Email format error (error code: 26303). Please enter it again`,
        error_26304:`Password above 8 characters is recommended (error code: 26304)`,
        error_26305:`Reuqest failed (error code: 26305). Please retry it`,
        error_26306:`Email has been registered, please <a href="https://account.anycoord.com/login">go to log in</a>`,
        error_26307:`Reuqest failed (error code: 26307). Please retry it`,
        error_26308:`Reuqest failed (error code: 26308). Please retry it`,
        error_26401:`Error code: 26401, please retry it`,
        error_26402:`Email has been verified (error code: 26402), please retry it`,
        error_26403:`Reuqest failed (error code: 26403). Please retry it`,
        error_26404:`Request failed (error code: 26404). Please retry it`,
        error_26501:`Error code: 26501. Please contact our <a href="mailto:support@anycoord.com">support team</a>.`,
        error_26502:`Error code: 26502. Please contact our <a href="mailto:support@anycoord.com">support team</a>.`,
        error_26503:`Email format error (error code: 26503). Please contact our <a href="mailto:support@anycoord.com">support team</a>.`,
        error_26504:`Request failed (error code: 26504). Please try again.`,
        error_26505:`The email address is not associate with any AnyCoord account. Please <a href="https://account.anycoord.com/register">sign up</a> for an account first.`,
        error_26506:`The email address was already verified.`,
        error_26507:`Request failed (error code: 26507). Please try again.`,
        error_26508:`Verification failed (error code: 26508). Please try again.`,
        error_26509:`Request failed (error code: 26509). Please try again.`,
        error_26510:`Error code: 26510. Please contact our <a href="mailto:support@anycoord.com">support team</a>.`,
        error_26601:`Error code: 26601, please contact us`,
        error_26602:`Error code: 26602, please contact us`,
        error_26603:`Reuqest failed (error code: 26603). Please retry it`,
        error_26604:`Error code: 26604, please contact us`,
        error_26605:`Error code: 26605, please contact us`,
        error_26701:`Error code: 26701, please contact us`,
        error_26702:`Reuqest failed (error code: 26702). Please retry it`,
        error_26703:`Error code: 26703, please contact us`,
        error_26704:`Error code: 26704, please contact us`,
        error_26705:`Wait for logging in (error code: 26705). Please retry it`,
        no_cookie:`You have turned on the Block all cookies function in your browser, so you cannot log in. Please go to Settings to check the box of Allow all cookies.`,
        error_26801:`Error code: 26801, please contact us`,
        error_26802:`Error code: 26802, please contact us`,
        error_26803:`Request failed (error code: 26803). Please retry it`,
        error_26804:`Request failed (error code: 26804). Please retry it`,
        error_order:`Request failed (error code: 27098), please try it again!`,
        error_order1:`The order query is incomplete（error code: `,
        error_order2:`）, please refresh and try it again.`,
        modify_email_title:`Modify Email`,
        modify_email_info:`You can use the modified email to log into your account.`,
        images_per:`Images per`,
        error_26101:`Error: 26101. Please contact us`,
        error_26102:`Error: 26102. Please contact us`,
        error_26103:`Request failed (error code:26103). Please try it again`,
        error_26104:`Error code: 26104, plesase retry it`,
        error_26105:`Error code: 26105, plesase retry it`,
        error_26106:`Delete failed (error code: 26106). Please try it again`,
        error_26201:`Error: 26201. Please contact us`,
        error_26202:`Request failed (error code:26202). Please try it again`,
        error_26001:`Error: 26001. Please contact us`,
        error_26002:`Error: 26002. Please contact us`,
        error_26003:`Error: 26003. Please contact us`,
        error_26004:`Error: 26004. Please contact us`,
        error_26005:`Request failed (error code:26005). Please try it again`,
        error_26006:`Error code: 26006, plesase retry it`,
        error_26008:`Error: 26008. Please contact us`,
        go_to_the_home_page:`Go to the Home page`,

        error_27101:`Request failed (error code: 27101). Please retry ist`,
        error_27201:`Error code: 27201, please contact us`,
        error_27202:`Error code: 27202, please retry it`,
        error_27203:`Request failed (error code: 27203). Please retry it`,
        error_27204:`Invalid code (error code: 27204).`,
        error_27205:`Request failed (error code: 27205). Please retry it`,
        error_27206:`Request failed (error code: 27206). Please retry it`,
        error_27207:`Request failed (error code: 27207). Please retry it`,
        no_history_found:`You haven't used any tool! <a href="/">Refresh</a> or <a href="https://www.anycoord.com/">Go to the official website</a>`,

        error_25301:`Error: 25301. Please contact us`,
        error_25302:`Error: 25302. Please contact us`,
        error_25303:`Request failed (error code: 25303). Please try it again`,
        error_25304:`Request failed (error code: 25304). Please try it again`,
        error_25305:`Request failed (error code: 25305). Please try it again`,
        error_25306:`Request failed (error code: 25306). Please try it again`,
        image_upscaler_p:`Image without watermark downloading benefits:`,
        Available_for:`Available for:`,
        credit_per:`1 credit per HD image`,
        still_valid:`Purchased plan(s) is still valid`,
        credit:`credit(s)`,
        hi:`Hi, %s!`,
        Personal_Information:`Personal Information`,
        Subscription_Status:`Subscription Status`,
        Access:`Access`,
        Subscription_Plan:`(Subscription Plan)`,
        No_orders:`No orders found.`,
        No_data:`No data`,

        Verified:`Verified!`,
        back_to_account_center:`Back to Account Center`,
        success:`Success!`,
        successfully:`You have successfully registered an account.`,
        Continue:`Continue`,
        Already:`Already have an account?`,
        Try:`Try`,
        Buy:`Buy`,
        Products:`Products`,
        Desktop_Software:`Desktop Software`,
        Free_Solutions:`Free Solutions`,
        Resource:`Resource`,
        Support:`Support`,
        Company:`Company`,
        Official_Store:`Official Store`,
        Select_Language:`Select Language`,
        Other_Languages:`Other Languages`,
        Select_Your_Language:`Select Your Language`,
        About_us:`About us`,
        Contact_us:`Contact us`,
        Press_Room:`Press Room`,
        Sitemap:`Sitemap`,
        FAQ_Center:`FAQ Center`,
        Support_Center:`Support Center`,
        Privacy_Policy:`Privacy Policy`,
        License_Agreement:`License Agreement`,
        Terms:`Terms & Conditions`,
        Copyright:`Copyright © 2024 AnyCoord Studio. All rights reserved.`,
        product_1:`Video Converter`,
        product_2:`Screen Recorder`,
        product_3:`DVD Creator`,
        product_4:`AnyCoord Player`,
        product_5:`Video Enhancer`,
        product_6:`Video Editor`,
        product_7:`Free Screen Recorder Online`,
        product_8:`Free Video Converter Online`,
        product_9:`Free Watermark Remover Online`,
        product_10:`Free Background Remover Online`,
        product_11:`Free Image Compressor Online`,
        product_12:`Free Image Upscaler Online`,
        product_13:`Free Audio Converter Online`,
        product_14:`Free Audio Recorder Online`,
        product_15:`Free Video Compressor Online`,
        product_16:`Free Video Joiner Online`,
        product_17:`Free HEIC Converter Online`,
        product_18:`Free Image Converter Online`,
    },
};